import { configureStore } from "@reduxjs/toolkit";

import userDataSlice from "./user-data.js";
import auth0Slice from "./auth0.js";
import notificationsSlice from "./notifications.js";

const store = configureStore({
    reducer: {
        auth0: auth0Slice.reducer,
        notifications: notificationsSlice.reducer,
        userData: userDataSlice.reducer
    },
});

export default store;
