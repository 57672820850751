import { createSlice } from "@reduxjs/toolkit";

const showNotificationUser = (state, action) => {
    state.notificationUser = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
    };
};

const initialState = {
    notificationUser: null
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        showNotificationUser
    },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
