import React, { useEffect, useState } from "react";
import {
    useLocation,
    Route,
    Switch,
    Redirect,
    useHistory,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

const Admin = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();



    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/index") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            {/* <Sidebar
                {...props}
                routes={routes}
                logo={{
                    innerLink: "/index",
                    imgSrc: require("../assets/img/brand/pc_logo_white.png")
                        .default,
                    imgAlt: "...",
                }}
                logoCollapsed={{
                    innerLink: "/index",
                    imgSrc: require("../assets/img/brand/logo_planalto_capital_rgb_positivo_PNG.png")
                        .default,
                    imgAlt: "...",
                }}
            /> */}
            <div className="main-content" ref={mainContent}>
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/index/index" />
                </Switch>
                {/* <Container fluid></Container> */}
            </div>
        </>
    );
};

export default Admin;
