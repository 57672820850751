import { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Jumbotron,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPlanaltoInfo } from "store/user-data-actions";
import SummaryFundTable from "components/Table/SummaryFundTable";
import RowWithTwoCols from "components/utils/RowWithTwoCols";
import CardWithGraph from "components/Charts/CardWithGraph";
import SummaryCard from "components/CardBoards/SummaryCard";
import AplicacoesTable from "components/Table/AplicacoesTable";
import ResgatesTable from "components/Table/ResgatesTable";
import LinePercentChart from "components/Charts/LinePercentChart";
import Loading from "components/utils/Loading";

const boxStyle = {
    "border-collapse": "collapse",
    "border-spacing": "0px",
    "background-color": "#365071",
    Margin: "0",
    padding: "0",
    width: "1000px",
};

const titleStyle = {
    "font-family": "roboto",
};

const parStyle = {
    "font-family": "roboto",
    "font-size": "25px",
};
const Index = (props) => {
    return (
        <Container fluid="xl">
            <br />
            <br />
            <br />

            <Row>
                <Jumbotron>
                    <div align="center">
                        <a
                            target="_blank"
                            href="https://planaltocapital.com.br/"
                        >
                            {" "}
                            <img
                                class="adapt-img"
                                src="https://www.planaltocapital.com.br/comunicacao/logos/logo_planalto_capital_rgb_positivo_PNG.png"
                                width="400"
                            />
                        </a>
                    </div>

                    <br />
                    <br />
                    <h1 className="display-1" style={titleStyle}>
                        Olá!
                    </h1>
                    <p className="lead" style={parStyle}>
                        Conforme informado anteriormente, a administração
                        fiduciária do Planalto Capital FIA foi transferida para
                        a Órama.
                        <br />
                        <br />
                        De agora em diante, para solicitações de aplicação e de
                        resgate, você deverá possuir cadastro na Órama.
                        <br />
                        <br />
                        Se você <strong>já for cliente</strong> da Órama, favor
                        realizar seu cadastro{" "}
                        <a
                            href="https://planaltogestao.orama.com.br/#/?portalRedirect=/transferencia-de-base/9362"
                            title="link para cadastro para clientes órama"
                            target="_blank"
                            data-saferedirecturl="https://www.google.com/url?q=https://planaltogestao.orama.com.br/%23/?portalRedirect%3D/transferencia-de-base/9362&amp;source=gmail&amp;ust=1659116122911000&amp;usg=AOvVaw3WbA7zDcwivy2D43JHyLaQ"
                        >
                            <strong>
                                {" "}
                                <i>
                                    {" "}
                                    <u>clicando aqui</u>.
                                </i>
                            </strong>
                        </a>
                        <br />
                        Se você <strong>não for</strong> cliente Órama,
                        <a
                            href="https://planaltogestao.orama.com.br/novo-cadastro/novo-cliente/9362"
                            title="link para cadastro"
                            target="_blank"
                            data-saferedirecturl="https://www.google.com/url?q=https://planaltogestao.orama.com.br/novo-cadastro/novo-cliente/9362&amp;source=gmail&amp;ust=1659116122911000&amp;usg=AOvVaw099iZSE94oa8HAFyMYr9gJ"
                        >
                            <strong>
                                {" "}
                                <i>
                                    {" "}
                                    <u>clique aqui</u> .
                                </i>
                            </strong>
                        </a>
                        <br />
                        <br />
                        Pedimos que realize o novo cadastro quando possível.
                        Qualquer dúvida, estamos à disposição{" "}
                    </p>
                    <hr className="my-2" />
                    <p>
                        Caso tenha alguma dúvida nos contate em{" "}
                        <strong>contato@planaltcapital.com.br</strong>
                    </p>
                </Jumbotron>
            </Row>
        </Container>
    );
};

export default Index;
