import { createSlice } from "@reduxjs/toolkit";




const initialState = {
    auth0Info: null,
    planaltoInfo: null
};

const updateUserAuth0Info = (state, action) => {
    state.auth0Info = {...action.payload}
}
const updateUserPlanaltoInfo = (state, action) => {
    state.planaltoInfo = {...action.payload}
}

const userDataSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        updateUserAuth0Info,
        updateUserPlanaltoInfo
    },
});

export const userDataActions = userDataSlice.actions;

export default userDataSlice;
