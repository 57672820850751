import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import Auth0ProviderWithHistory from "components/auth0/Auth0ProviderWithHistory";
import { Provider } from "react-redux";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import EmailVerification from "components/EmailVerification/EmailVerification";
import store from "store/index";
import AdminLayout from "layouts/Admin.js";
import ForceLogin from "components/auth0/ForceLogin";

const params = new URLSearchParams(window.location.search);
const emailVerification = params.get("emailVerification");
const email = params.get("email");
const message = params.get("message");
const success = params.get("success");


const render = () => {
    return (
        <BrowserRouter>
                    <Provider store={store}>
                        <Route
                            path="/"
                            render={(props) => <AdminLayout {...props} />}
                        />
                    </Provider>
        </BrowserRouter>
    );
};

ReactDOM.render(render(), document.getElementById("root"));
